<template>
  <div class="index-container" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="home-product pos-relative">
      <!-- 搜索 -->
      <div class="padded-10 pos-relative flex-box">
        <h1 class="logo margin-r10"></h1>
        <div class="flex-box-item m-input-c">
          <el-input placeholder="我要采购" v-model="search_content" class="n-border">
            <el-select v-model="m_select" slot="prepend" placeholder="请选择">
              <el-option label="名称" value="1"></el-option>
              <!-- <el-option label="苗店" value="2"></el-option> -->
            </el-select>
            <div slot="append" class="searchBtn" @click="getHomeTree">
              <i class="el-icon-search"></i>
              <span>搜索</span>
            </div>
          </el-input>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="margin-t10">
        <!-- <div class="addCity">
          <el-radio-group
            v-model="tabPosition"
            size="small"
            span-color="#fff"
            fill="#14c4ad"
          >
            <el-radio-button label="top">城市查询</el-radio-button>
          </el-radio-group>
          <span class="margin-l10"></span>
          <cascader-component
            :selectValue="selectedOptions"
            :size="'medium'"
            parameters="name"
            :options="regionData"
            :placeholder="'请选择城市'"
            @change="addressChange"
          >
          </cascader-component>
        </div> -->
        <h2>推荐苗木</h2>
        <div class="pos-relative">
          <div class="tree-list-box">
            <div v-for="good in goodsProduct" :key="good.id" class="list-item">
              <SelectTree :item="good" @showView="showView" :is_preview="true" />
            </div>
          </div>
          <div class="box-center padded-10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              default
              layout="prev, pager, next"
              :total="50"
            >
            </el-pagination>
          </div>
        </div>

        <el-menu mode="horizontal"> </el-menu>
        <div class="margin-t20">
          <h2>最新苗木</h2>
          <div class="pos-relative padded-b40">
            <div class="tree-list-box">
              <div v-for="good in friendTreeList" :key="good.id" class="list-item">
                <SelectTree
                  :item="good"
                  :is_show_updated="true"
                  :is_show_price="false"
                  @showView="showView"
                  :is_preview="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览 -->

    <el-dialog
      :title="titlePreview"
      :visible.sync="dialogTableVisible"
      top="10%"
      width="600px"
    >
      <div class="flex-box box-content">
        <div class="swiper-image">
          <el-image
            :src="imageList[0]"
            :preview-src-list="imageList"
            fit="scale-down"
          ></el-image>
        </div>
        <div class="flex-box-item margin-l10 fs-12 box-text">
          <div class="flex-box margin-t10">
            <div class="c-333">价格：</div>
            <div class="flex-box-item text-al">
              <span class="fs-12" v-if="parseInt(form.price)">￥</span>
              <span class="fs-12">
                {{ formatDecimal(form.price) || "~" }}
              </span>
              <span class="fs-12" v-if="parseInt(form.price)">
                {{ form.price_unit }}
              </span>
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">
              {{
                form.xdj_status == 1
                  ? "胸径："
                  : form.xdj_status == 2
                  ? "地径："
                  : "胸地径"
              }}
            </div>
            <div class="flex-box-item text-al">
              {{
                form.xdj ||
                formatDecimal(form.xdj_min) + "~" + formatDecimal(form.xdj_max)
              }}
              cm
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">高度：</div>
            <div class="flex-box-item text-al">
              {{
                form.height ||
                formatDecimal(form.height_min) + "~" + formatDecimal(form.height_max)
              }}
              cm
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">冠幅：</div>
            <div class="flex-box-item text-al">
              {{
                form.gf || formatDecimal(form.gf_min) + "~" + formatDecimal(form.gf_max)
              }}
              cm
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">分枝点数：</div>
            <div class="flex-box-item text-al">
              {{ form.fzd_num || "~" }}
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">分枝点高度：</div>
            <div class="flex-box-item text-al">
              {{
                form.fzd_height ||
                formatDecimal(form.fzd_height_min) +
                  "~" +
                  formatDecimal(form.fzd_height_max)
              }}
              cm
            </div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">苗场：</div>
            <div class="flex-box-item text-al">{{ form.site }}</div>
          </div>

          <div class="flex-box margin-t10">
            <div class="c-333">位置：</div>
            <div class="flex-box-item text-al">{{ form.location }}</div>
          </div>
          <div class="flex-box margin-t10">
            <div class="c-333">日期：</div>
            <div class="flex-box-item text-al">{{ form.date_time }}</div>
          </div>
          <div
            class="flex-box margin-t10 max-w-300"
            v-if="form.is_show_remark && form.remark"
          >
            <div class="c-333">备注：</div>
            <div class="flex-box-item text-ellipsis-2 text-al">
              {{ form.remark }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <LayoutFooter />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { translateTitle } from "@/utils/i18n";
import { regionData } from "element-china-area-data";
import { queryDataList, getLatestList, queryDataDetail } from "../serviceApi";
import LayoutFooter from "./LayoutFooter";

export default {
  name: "ahome",
  components: { LayoutFooter },
  data() {
    return {
      m_select: "名称",
      dialogTableVisible: false,
      titlePreview: null,
      regionData: regionData,
      search_content: null,
      selectedOptions: "",
      goodsProduct: [],
      friendTreeList: [],
      curPage: 1,
      pageSize: 10,
      dataList: [],
      tabPosition: "top",
      fullscreenLoading: true,
      loading: false,
      imageList: [],
      images_info: [],
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      title: "settings/title",
    }),
  },
  mounted() {
    this.fullscreenLoading = false;
    this.getHomeTree();
    this.queryLatestList();
  },
  methods: {
    ...mapActions({
      userLogin: "user/login",
      saveTheme: "settings/saveTheme",
    }),
    translateTitle,
    // 详情
    async showView(e) {
      this.dialogTableVisible = true;
      this.imageList = [];
      const { data, code } = await queryDataDetail({
        item_id: e.item_id,
      });
      if (code == 200) {
        const { images_data, item_name } = data;
        this.imageList = images_data.map((x) => {
          return x;
        });
        this.titlePreview = item_name;
        this.form = data;
        console.log(images_data, e);
      }
    },
    addressChange(arr) {
      console.log(arr, this.selectedOptions);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getHomeTree();
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.getHomeTree();
    },
    // 获取商品列表
    async getHomeTree() {
      let that = this;
      const whereOpt = Object.assign({
        page: this.curPage,
        page_size: this.pageSize,
      });
      that.search_content &&
        Object.assign(whereOpt, {
          keyword: that.search_content,
        });
      const { data } = await queryDataList({ ...whereOpt });
      const { rows } = data;
      this.goodsProduct = rows;
    },
    // 获取推荐您可能喜欢的苗木
    async queryLatestList() {
      const { data } = await getLatestList();
      const { rows } = data;
      this.friendTreeList = rows;
    },
    formatDecimal(old) {
      old = parseFloat(old);
      old = (old || "").toString();
      //拷贝一份 返回去掉零的新串
      let newstr = old;
      //循环变量 小数部分长度
      let leng = old.length - old.indexOf(".") - 1;
      //判断是否有效数
      if (old.indexOf(".") > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
            let k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == ".") {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return old;
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-list-box {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.tree-list-box .list-item {
  width: calc(20% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.box-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.box-content {
  margin: 0 auto;
}
.swiper-image {
  width: 300px;
}
</style>
