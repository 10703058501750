var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "index-container",
    },
    [
      _c("div", { staticClass: "home-product pos-relative" }, [
        _c("div", { staticClass: "padded-10 pos-relative flex-box" }, [
          _c("h1", { staticClass: "logo margin-r10" }),
          _c(
            "div",
            { staticClass: "flex-box-item m-input-c" },
            [
              _c(
                "el-input",
                {
                  staticClass: "n-border",
                  attrs: { placeholder: "我要采购" },
                  model: {
                    value: _vm.search_content,
                    callback: function ($$v) {
                      _vm.search_content = $$v
                    },
                    expression: "search_content",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.m_select,
                        callback: function ($$v) {
                          _vm.m_select = $$v
                        },
                        expression: "m_select",
                      },
                    },
                    [_c("el-option", { attrs: { label: "名称", value: "1" } })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "searchBtn",
                      attrs: { slot: "append" },
                      on: { click: _vm.getHomeTree },
                      slot: "append",
                    },
                    [
                      _c("i", { staticClass: "el-icon-search" }),
                      _c("span", [_vm._v("搜索")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "margin-t10" },
          [
            _c("h2", [_vm._v("推荐苗木")]),
            _c("div", { staticClass: "pos-relative" }, [
              _c(
                "div",
                { staticClass: "tree-list-box" },
                _vm._l(_vm.goodsProduct, function (good) {
                  return _c(
                    "div",
                    { key: good.id, staticClass: "list-item" },
                    [
                      _c("SelectTree", {
                        attrs: { item: good, is_preview: true },
                        on: { showView: _vm.showView },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "box-center padded-10" },
                [
                  _c("el-pagination", {
                    attrs: {
                      default: "",
                      layout: "prev, pager, next",
                      total: 50,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("el-menu", { attrs: { mode: "horizontal" } }),
            _c("div", { staticClass: "margin-t20" }, [
              _c("h2", [_vm._v("最新苗木")]),
              _c("div", { staticClass: "pos-relative padded-b40" }, [
                _c(
                  "div",
                  { staticClass: "tree-list-box" },
                  _vm._l(_vm.friendTreeList, function (good) {
                    return _c(
                      "div",
                      { key: good.id, staticClass: "list-item" },
                      [
                        _c("SelectTree", {
                          attrs: {
                            item: good,
                            is_show_updated: true,
                            is_show_price: false,
                            is_preview: true,
                          },
                          on: { showView: _vm.showView },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titlePreview,
            visible: _vm.dialogTableVisible,
            top: "10%",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-box box-content" }, [
            _c(
              "div",
              { staticClass: "swiper-image" },
              [
                _c("el-image", {
                  attrs: {
                    src: _vm.imageList[0],
                    "preview-src-list": _vm.imageList,
                    fit: "scale-down",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-box-item margin-l10 fs-12 box-text" },
              [
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("价格：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    parseInt(_vm.form.price)
                      ? _c("span", { staticClass: "fs-12" }, [_vm._v("￥")])
                      : _vm._e(),
                    _c("span", { staticClass: "fs-12" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDecimal(_vm.form.price) || "~") +
                          " "
                      ),
                    ]),
                    parseInt(_vm.form.price)
                      ? _c("span", { staticClass: "fs-12" }, [
                          _vm._v(" " + _vm._s(_vm.form.price_unit) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.xdj_status == 1
                            ? "胸径："
                            : _vm.form.xdj_status == 2
                            ? "地径："
                            : "胸地径"
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.xdj ||
                            _vm.formatDecimal(_vm.form.xdj_min) +
                              "~" +
                              _vm.formatDecimal(_vm.form.xdj_max)
                        ) +
                        " cm "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("高度：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.height ||
                            _vm.formatDecimal(_vm.form.height_min) +
                              "~" +
                              _vm.formatDecimal(_vm.form.height_max)
                        ) +
                        " cm "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("冠幅：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.gf ||
                            _vm.formatDecimal(_vm.form.gf_min) +
                              "~" +
                              _vm.formatDecimal(_vm.form.gf_max)
                        ) +
                        " cm "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("分枝点数：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(" " + _vm._s(_vm.form.fzd_num || "~") + " "),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("分枝点高度：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.fzd_height ||
                            _vm.formatDecimal(_vm.form.fzd_height_min) +
                              "~" +
                              _vm.formatDecimal(_vm.form.fzd_height_max)
                        ) +
                        " cm "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("苗场：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(_vm._s(_vm.form.site)),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("位置：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(_vm._s(_vm.form.location)),
                  ]),
                ]),
                _c("div", { staticClass: "flex-box margin-t10" }, [
                  _c("div", { staticClass: "c-333" }, [_vm._v("日期：")]),
                  _c("div", { staticClass: "flex-box-item text-al" }, [
                    _vm._v(_vm._s(_vm.form.date_time)),
                  ]),
                ]),
                _vm.form.is_show_remark && _vm.form.remark
                  ? _c(
                      "div",
                      { staticClass: "flex-box margin-t10 max-w-300" },
                      [
                        _c("div", { staticClass: "c-333" }, [_vm._v("备注：")]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex-box-item text-ellipsis-2 text-al",
                          },
                          [_vm._v(" " + _vm._s(_vm.form.remark) + " ")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _c("LayoutFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }