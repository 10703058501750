var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "app_footer" }, [
    _vm._m(0),
    _c("div", { staticClass: "copyright" }, [
      _c(
        "p",
        [
          _c("a", { attrs: { href: "", target: "_blank" } }, [
            _vm._v("版权声明"),
          ]),
          _c("router-link", { attrs: { to: "/privacy" } }, [
            _c(
              "a",
              {
                attrs: { href: "/privacy" },
                on: { click: _vm.personalAgreement },
              },
              [_vm._v("隐私条款")]
            ),
          ]),
        ],
        1
      ),
      _c("p"),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c("div", { staticClass: "container flex-box" }, [
        _c("div", { staticClass: "flex-box-item text-ac" }, [
          _c("div", { staticClass: "miniAppqr" }),
          _c("div", { staticClass: "download" }, [
            _c("span", [_vm._v("立马下载APP")]),
          ]),
        ]),
        _c("div", { staticClass: "flex-box-item text-ac" }, [
          _c("div", { staticClass: "miniwxqr" }),
          _c("div", { staticClass: "download" }, [
            _c("span", [_vm._v("扫描微信小程序")]),
          ]),
        ]),
        _c("div", { staticClass: "flex-box-item justify-item-center" }, [
          _c("p", [
            _c("i", { staticClass: "el-icon-phone-outline fs-20" }),
            _vm._v(" 4000-168568"),
          ]),
          _c("p", { staticClass: "margin-t10 margin-b10" }, [
            _vm._v("qq群: 461603995"),
          ]),
          _c("p", [_vm._v("微信公众号:EZland")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2024 易号苗信息科技有限公司 "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
        [_vm._v("沪ICP备2024053539号-1 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }