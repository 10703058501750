import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/website/search-list',
    method: 'post',
    data,
  })
}
// 最新苗木
export function getLatestList (data) {
  return request({
    url: '/website/latest-list',
    method: 'post',
    data,
  })
}
// 详情
export function queryDataDetail (data) {
  return request({
    url: '/index/item-data',
    method: 'post',
    data,
  })
}

// 隐私协议
export function queryUserPrivacy (data) {
  return request({
    url: '/user-center/data',
    method: 'post',
    data,
  })
}

