<template>
  <footer class="app_footer">
    <!-- 联系我们 -->
    <div class="contact">
      <div class="container flex-box">
        <div class="flex-box-item text-ac">
          <div class="miniAppqr"></div>
          <div class="download">
            <span>立马下载APP</span>
          </div>
        </div>
        <div class="flex-box-item text-ac">
          <div class="miniwxqr"></div>
          <div class="download">
            <span>扫描微信小程序</span>
          </div>
        </div>
        <div class="flex-box-item justify-item-center">
          <p><i class="el-icon-phone-outline fs-20"></i> 4000-168568</p>
          <p class="margin-t10 margin-b10">qq群: 461603995</p>
          <p>微信公众号:EZland</p>
        </div>
      </div>
    </div>
    <!-- 其它 -->
    <div class="copyright">
      <p>
        <a href="" target="_blank">版权声明</a>
        <router-link to="/privacy">
          <a href="/privacy" @click="personalAgreement">隐私条款</a>
        </router-link>
      </p>
      <p></p>
      <p>
        2024 易号苗信息科技有限公司
        <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2024053539号-1 </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    personalAgreement() {
      this.$router.push("/agreement");
    },
    personalPrivacy() {
      this.$router.push("/privacy");
    },
  },
};
</script>

<style scoped lang="scss">
.app_footer {
  overflow: hidden;
  background-color: #2e9244;
  padding-top: 20px;
  color: #fff;

  .contact {
    .container {
      padding: 60px 0 40px 25px;
      display: flex;
    }

    .qrcode {
      height: 120px;
    }

    .download {
      padding-top: 5px;
      font-size: 14px;
      width: auto;
      height: auto;
      border: none;

      span {
        display: block;
      }

      a {
        display: block;
        line-height: 1;
        padding: 10px 25px;
        margin-top: 5px;
        color: #fff;
        border-radius: 2px;
        background-color: #333;
      }
    }

    .hotline {
      padding-top: 20px;
      font-size: 22px;
      width: auto;
      height: auto;
      border: none;

      small {
        display: block;
        font-size: 15px;
        color: #999;
      }
    }
  }

  .extra {
    background-color: #333;
  }

  .copyright {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 15px;

    p {
      line-height: 1;
      margin-bottom: 20px;
      font-size: 12px;
    }

    a {
      line-height: 1;
      padding: 0 10px;
      color: #fff;
      border-right: 1px solid #fff;

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
